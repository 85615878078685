import useTitle from "../../hooks/useTitle";
import StyleProvider from "../../shared/StyleProvider";

export default function Support() {
  useTitle("Support");
  return (
    <StyleProvider>
      <div className="pb-10 px-3 md:px-12 py-8 h-screen">
        <h1 className="text-2xl md:text-3xl lg:text-4xl py-4 text-center font-lemonMilk text-black">
          Support
        </h1>
        <p className="text-black pt-5 md:pt-8 w-full lg:w-4/5 mx-auto text-base md:text-lg text-center font-monospaceTypewriter">
          Email:{" "}
          <a
            href="mailto:support@mirrorltd.com"
            className="hover:text-gradientPrimary duration-300"
          >
            support@mirrorltd.com
          </a>
        </p>
      </div>
    </StyleProvider>
  );
}
