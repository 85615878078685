import { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import router from "./routes/router";
import Loading from "./shared/Loading";

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e: any) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });

    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }, []);

  useEffect(() => {
    const disableRightClick = (e: any) => {
      if (e.button === 2) {
        e.preventDefault();
      } else if (e.button === 0) {
        e.preventDefault();
      }
    };
    document.addEventListener("contextmenu", disableRightClick, {
      passive: false,
    });
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  return <>{isLoading ? <Loading /> : <RouterProvider router={router} />}</>;
};

export default App;
